/* eslint-disable import/no-anonymous-default-export */
import time from './pt-BR/time.json';
import review from './pt-BR/review.json';
import result from './pt-BR/result.json';
import error from './pt-BR/error.json';
import notification from './pt-BR/notification.json';

export default {
  time,
  review,
  result,
  error,
  notification,
};

import { lazy } from 'react';
const Review = lazy(() => import('../pages/Review'));
const Result = lazy(() => import('../pages/Result'));
const Expired = lazy(() => import('../pages/Expired'));

export const ROUTES = [
  { path: '/result', component: Result, title: 'Concluído a avaliação' },
  { path: '/expired', component: Expired, title: 'Avaliação fora do prazo' },
  { path: '/:linkId', component: Review, title: 'Avaliação' },
];

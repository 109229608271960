/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from "react-router-dom";
import { Progress, PageHeader, Row, Col, Typography } from 'antd'; 
import Icon from '../Icon';
import Api from '../../services/api';

import './Header.less';

const HEADERS = {
  'survey.answer': { 
    title: 'Avaliação', 
    description: 'Segue as perguntas para realização da avaliação' 
  },
}

const { Title, Text } = Typography;

function Header({ location, routes }) {
  const history = useHistory();
  const inputRef = useRef();
  const [offsetTop, setOffsetTop] = useState(0);
  const [survey, setSurvey] = useState({});
  
  // eslint-disable-next-line no-unused-vars
  const [_, linkId, group, action] = location.pathname.split('/');

  const getLink = async () => {
    const response = await Api.get(linkId);
    if (!response?.data) return;
    setSurvey(response.data);
  }
  
  const percent = routes.findIndex(({ path }) => {
    const [_Original, linkIdOriginal, ...routeOriginal] = path?.split('/')
    const [_, linkId, ...route] = location.pathname?.split('/')
    return routeOriginal.join('/') === route.join('/');
  }) || 0;
  
  const onBack = ()=> history.goBack();
  
  const scrollHandler = () => {
    const { top } = inputRef.current.getBoundingClientRect();
    setOffsetTop(top);
  };
  
  useEffect(() => {
    scrollHandler();
    getLink();
    window.addEventListener('scroll', scrollHandler, true);
    return () => window.removeEventListener('scroll', scrollHandler, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (!survey) return;

  const message = HEADERS[`${group || 'survey'}.${action || 'answer'}`];

  return (
    <div ref={inputRef}>
      {(offsetTop >= 0) && (
        <PageHeader
          className="header-fixed"
          key="pageheader"
          onBack={onBack}
          backIcon={percent === 0 ? null : <Icon type="arrow-left" />}
          title={(
            <Row type="flex" gutter={36} justify="space-between" align="middle">
              <Col span={4}>
                <div className="logo_avatar" />
              </Col>
              <Col span={20}>
                <Title level={4}>
                  {message?.title}
                </Title>
                <Text ellipsis type="secondary" style={{ fontSize: 16 }}>
                  {(message?.description || '').replace('XXXX', survey?.name).replace('YYYY', survey?.company?.name)}
                </Text>
              </Col>
            </Row>
          )}
        >
        </PageHeader>
      )}
    </div>
  )
}

export default withRouter(Header);
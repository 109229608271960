import { t } from './locales';
import React, { Suspense } from 'react';
import { Loading, Layout, Routing } from './components';
import { notification } from 'antd';
import { BrowserRouter, Switch } from 'react-router-dom';
import numeral from 'numeral';
import 'numeral/locales';
import { ROUTES } from './config/routes';
import SiderLeft from './components/SiderLeft';

import './App.less';

const Page404 = React.lazy(() => import('./pages/Page404'));
numeral.locale('pt-br');

function App() {
  const openNotification = (
    message = t('notification.successfullSave'),
    description,
  ) => {
    const args = {
      message,
      description,
    };
    notification.open(args);
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Layout className="App" style={{ flexDirection: 'row' }}>
          <SiderLeft />
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Switch>
              {ROUTES.map(({ path, title, component }) => (
                <Routing
                  key={path}
                  path={path}
                  component={component}
                  title={title}
                  exact={true}
                  struct={{
                    openNotification,
                  }}
                />
              ))}

              <Routing path="/error" component={Page404} title="Erro" />
              <Routing path="*" component={Page404} title="" />
            </Switch>
          </div>
          {/* <Header
            routes={ROUTES}
            data={data}
          /> */}
          {/* <LayoutContent>
            <Switch>
              {ROUTES.map(({ path, title, component }) => (
                <Routing
                  key={path}
                  path={path}
                  component={component}
                  title={title}
                  exact={true}
                  struct={{ 
                    data, 
                    onChange,
                    onReset,
                    openNotification
                  }}
                />
              ))}
              <Routing path="*" component={Page404} />
            </Switch>
          </LayoutContent> */}
          {/* <FooterView
            links={links}
          /> */}
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

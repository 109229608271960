import React from 'react';
import { withRouter } from 'react-router';

import { Row, Col } from 'antd';

function ReviewScreen() {
  return (
    <Row
      style={{
        width: '10%',
        height: '100vh',
        borderRight: '1px solid #E0E0E0',
      }}
    >
      <Col span={24} style={{ textAlign: 'center' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <svg
            width="128"
            height="41"
            viewBox="0 0 128 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M86.7423 16.6001L81.6312 13.0945V24.3646L63.5342 11.9462L54.5451 18.1158V13.9322L63.5293 7.76526L78.0538 17.7294V10.7182L63.5293 0.748047L49.4341 10.4201V21.6236L45.8566 24.079V12.8029L40.7461 16.3085V34.6078L63.5342 18.9634L72.9433 25.4214V30.2274L63.7031 23.8844L49.4341 33.678V40.6952L63.7031 30.9016L78.0538 40.7502V28.9297L86.7423 34.8934V16.6001Z"
              fill="#E0E5F5"
            />
          </svg>
        </div>
      </Col>
    </Row>
  );
}

export default withRouter(ReviewScreen);

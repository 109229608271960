import { I18n } from 'i18n-js';
import ptBR from './pt-BR';

const i18n = new I18n({
  ptBR,
});

i18n.locale = 'ptBR';

export const t = (key, options) =>
  i18n.t(key, options);
import React from 'react';
import { Upload } from 'antd';
import Icon from '../Icon';

import './Upload.less';

const { Dragger } = Upload;

const AvatarView = ({ styles, avatar, name, action, onChange, multiple = false, ...restProps }) => { 
  return (
    <div className="avatar-upload">
      <Dragger
        withCredentials
        multiple={multiple}
        name={name}
        action={action}
        headers={{
          enctype: 'multipart/form-data',
        }}
        onChange={onChange}
        {...restProps}
      >
        <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">Arraste uma foto aqui ou selecione um aquivo</p>
        <p className="ant-upload-hint">Arquivos aceitos: <b>JPEG</b>, <b>JPG</b> e <b>PNG</b> com tamanho de até <b>3mb</b></p>
      </Dragger>
    </div>
  );
};

export default AvatarView;
